import React, { useEffect, useState } from 'react';

import invariant from 'tiny-invariant';

import { getLocationData } from '../../api/ipinfo/getLocationData';
import { DEFAULT_COORDINATES } from '../../components/Maps/constants/constants';
import { getCloudfrontHeadersCookie } from '../../helpers/cookies';
import { isBrowser } from '../../helpers/environment/isBrowser';
import { reportMykissError } from '../../helpers/errorHandling';
import { useHandleUnmount } from '../../helpers/hooks/useHandleUnmount';
import { geolocationPermissisonGranted } from '../../helpers/location/geolocationPermissionGranted';
import { requestLocation } from '../../helpers/location/requestLocation';
import { ICloudfrontHeaders, ICoordinates } from '../../interfaces/geo';

import { getCoordinatesFromLocationData } from './helpers/getCoordinatesFromLocationData';

type CoordsSource = 'geolocation_api' | 'ip' | 'default';
interface GeolocationState {
  isLoaded: boolean;
  coordinates: ICoordinates;
  source: CoordsSource;
  countryCode?: string;
  regionCode?: string;
  locationData: ICloudfrontHeaders | null;
}

const GeolocationContext = React.createContext<GeolocationState>({
  isLoaded: false,
  source: 'default' as const,
  coordinates: DEFAULT_COORDINATES,
  locationData: null,
});

async function getLocationFromBrowser(): Promise<ICoordinates | null> {
  const isGranted = await geolocationPermissisonGranted();
  if (isGranted) {
    return requestLocation();
  }
  return null;
}

const GeolocationProvider = ({ children }: { children: React.ReactNode }): React.ReactElement => {
  const [locationData, setLocationData] = useState<ICloudfrontHeaders | null>(() =>
    getCloudfrontHeadersCookie(),
  );
  const [{ coordinates, source }, setCoordinates] = useState<{
    coordinates: ICoordinates;
    source: CoordsSource;
  }>(() => getCoordinatesFromLocationData(locationData));
  const [isLoaded, setIsLoaded] = useState(false);
  const unmounted = useHandleUnmount();

  useEffect(() => {
    if (isBrowser() && !locationData) {
      getLocationData()
        .then(newLocationData => {
          if (!unmounted.current) {
            setLocationData(newLocationData);
          }
          if (source !== 'geolocation_api') {
            if (!unmounted.current) {
              setCoordinates(getCoordinatesFromLocationData(newLocationData));
            }
          }
        })
        .catch(reportMykissError);
    }
    // Only runs on initial app load to get the user location data if the server didn't provide it.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getLocationFromBrowser()
      .then(location => {
        if (location) {
          setCoordinates({ coordinates: location, source: 'geolocation_api' });
        }
        setIsLoaded(true);
      })
      .catch(error => {
        setIsLoaded(true);
        reportMykissError(error);
      });
  }, []);

  return (
    <GeolocationContext.Provider
      value={{
        coordinates,
        isLoaded,
        source,
        countryCode: locationData?.countryCode || undefined,
        regionCode: locationData?.regionCode || undefined,
        locationData,
      }}
    >
      {children}
    </GeolocationContext.Provider>
  );
};

function useGeolocation(): GeolocationState {
  const context = React.useContext(GeolocationContext);
  invariant(context !== null);

  return context;
}

export { useGeolocation, GeolocationProvider, GeolocationContext };
