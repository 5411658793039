import * as React from 'react';

import NextImage from 'next/image';

import circleBlack from './circle_black.svg';
import circleGreen from './circle_green.svg';
import styles from './StatusIndicator.module.css';

interface IProps {
  id: string;
  status: boolean;
  tooltipDescription?: string;
}

export const StatusIndicator = ({ status, tooltipDescription, id }: IProps) => (
  <div id={id} className={styles.tooltip}>
    {tooltipDescription?.length ? (
      <span className={styles.tooltipText}>{tooltipDescription}</span>
    ) : (
      <></>
    )}
    <NextImage
      alt={status ? 'On' : 'Off'}
      width={22}
      height={22}
      src={status ? circleGreen : circleBlack}
    />
  </div>
);
