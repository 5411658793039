import React from 'react';

import NextImage from 'next/image';

import { useAnalytics } from '../../contexts/AnalyticsContext/AnalyticsContext';
import { trackNavigation } from '../../helpers/analytics';
import { InternalLink } from '../Clickables/InternalLink/InternalLink';

import fishbrainLogo from './assets/logo.svg';
import fishbrainLogoSmall from './assets/logo_small.svg';

const FULL_LOGO_HEIGHT = 42;
const FULL_LOGO_WIDTH = 192;
interface LogoProps {
  to?: string;
  fullLogoOnTablet?: boolean;
  fullLogo?: boolean;
  fullLogoHeight?: number;
  fullLogoWidth?: number;
}

export const Logo = ({
  to = '/',
  fullLogoOnTablet = false,
  fullLogo = false,
  fullLogoHeight = FULL_LOGO_HEIGHT,
  fullLogoWidth = FULL_LOGO_WIDTH,
}: LogoProps) => {
  const { currentPage } = useAnalytics();
  return (
    <InternalLink
      to={to}
      onClick={() => {
        trackNavigation({ path: to, currentPage });
      }}
    >
      {fullLogo ? (
        <NextImage
          alt="Fishbrain"
          width={fullLogoWidth}
          height={fullLogoHeight}
          src={fishbrainLogo}
          className="block"
        />
      ) : (
        <>
          <NextImage
            alt="Fishbrain"
            width={34}
            height={30}
            src={fishbrainLogoSmall}
            className={`block ${fullLogoOnTablet ? 'sm:hidden' : 'md:hidden'}`}
          />
          <NextImage
            alt="Fishbrain"
            width={192}
            height={42}
            src={fishbrainLogo}
            className={`hidden ${fullLogoOnTablet ? 'sm:block' : 'md:block'}`}
          />
        </>
      )}
    </InternalLink>
  );
};

// Default export to allow for React.memo

export default React.memo<LogoProps>(Logo);
